import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import Button from '../Button/Button';

const getReadMoreURL = (post) => {
  switch (post.sys.contentType.sys.id) {
    case 'portfolioPiece':
      return `/portfolio/${post.slug}`;

    case 'blogEntry':
      return `/resources/blog/${post.slug}`;

    default:
      return '/';
  }
};

export default function PostCard({ post }) {
  return (
    <div className="post-card">
      <Link to={getReadMoreURL(post)} tabIndex="0">
        <div className="image-wrapper">
          <GatsbyImage
            alt={
              post.cardImage?.description ||
              post.cardImage?.title ||
              post.heroImage?.description ||
              post.heroImage?.title ||
              ''
            }
            image={
              post.cardImage?.gatsbyImageData || post.heroImage?.gatsbyImageData
            }
          />
        </div>
        <div className="details">
          <h3 className="post-card-content-title">{post.title}</h3>
          <p className="post-card-content-text">
            {post.cardSummaryText?.cardSummaryText || ''}
          </p>
          <div className="post-card-content-footer">
            <p>{post.publishedDate}</p>
            <Button buttonStyle="tertiary">Read More</Button>
          </div>
        </div>
      </Link>
    </div>
  );
}
