import { Link, graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Seo from '../components/Seo';
import BlogList from '../components/Section/BlogList';
import SectionContainer from '../components/SectionContainer';
import ContentWrapper from '../components/ContentWrapper';

export default function NotFoundPage({ location }) {
  const data = useStaticQuery(graphql`
    query {
      blogPosts: allContentfulBlogEntry(
        limit: 6
        sort: { fields: [publishedDate], order: DESC }
      ) {
        nodes {
          sys {
            contentType {
              sys {
                id
              }
            }
          }
          id
          category
          title: blogTitle
          publishedDate(formatString: "MMM Do YYYY")
          slug
          cardSummaryText {
            cardSummaryText
          }
          heroImage {
            title
            description
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          cardImage {
            title
            description
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  `);

  return (
    <div className="page-404">
      <Seo
        title="404: Not Found"
        location={location}
        pathname={location.pathname}
      />
      <SectionContainer backgroundColor="white" className="top">
        <ContentWrapper>
          <h1>404: Not Found</h1>
          <p>That page couldn't be found.</p>
          <Link to="/">Head to the home page</Link>
          <span className="or">or</span>
          <h2>Dig into what's recent at CrossComm:</h2>
        </ContentWrapper>
      </SectionContainer>
      {data.blogPosts ? (
        <BlogList data={data.blogPosts} showCategories={false} />
      ) : null}
      <SectionContainer backgroundColor="white" className="bottom">
        <ContentWrapper>
          <h2>Find out more...</h2>
          <p>
            <Link to="/resources/blog/">Check out all of resources here</Link>
          </p>
        </ContentWrapper>
      </SectionContainer>
    </div>
  );
}
